<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      />
      <b10-file-gallery
        class="pa-3"
        :images="[formattedItem]"
      />
      <div
        class="ma-3"
      >
        <v-btn
          v-if="formattedItem.esPlano"
          block
          color="secondary"
          dark
          @click="clickVerPlano"
        >
          <v-icon
            left
          >
            {{ $vuetify.icons.values.plano }}
          </v-icon> Ver Plano
        </v-btn>
      </div>
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import { extractFileName } from '@/utils/files'
import { TFICHERO, TABLA } from '@/utils/consts'
import Data from './FicheroViewData'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        'nombre_fichero',
        { name: 'fcreacion', filter: this.$options.filters.shortDateTime },
        'tfichero_descripcion',
        'observaciones',
        'carpeta',
      ],
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idfichero
        item.title = extractFileName(item.nombre_fichero)
        item.subtitle = item.tfichero_descripcion
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        item.esPlano = item.idclasificacion_tfichero === TFICHERO.clasificacion.planos && this.routeParams.idtabla == TABLA.sistema.idtabla
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = extractFileName(this.item.dataset.nombre_fichero)
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectFichero(this, this.routeParams.idfichero)
        if (dataset) {
          dataset['blobUrl'] = await this.$api.getBlobUrl(dataset)
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    clickVerPlano () {
      this.$appRouter.push({
        name: 'sistemas__material-sistema-plano-view',
        params: {
          idsistema: this.routeParams.id,
          idfichero: this.routeParams.idfichero,
        }
      })
    }
  },
}
</script>
